<template>
  <div class="content md flex-box vertical" v-if="list.length">
    <div class="flex-grow flex-box vertical scroll-area">
      <a-descriptions title="基本信息" bordered>
        <a-descriptions-item label="堂口名称">
          {{ (detail.tangkou && detail.tangkou.name) || detail.tangkou_name || "-" }}
        </a-descriptions-item>
        <a-descriptions-item label="部门主管">
          <open-data type="userName" :openid="detail.tangkou_director" />
        </a-descriptions-item>
        <a-descriptions-item label="申购日期">
          {{ timestampConvertString(detail.subscribe_date, "yyyy-MM-dd") || "-" }}
        </a-descriptions-item>
        <a-descriptions-item label="备注">
          {{ detail.notes || "-" }}
        </a-descriptions-item>
        <a-descriptions-item label="附件">
          <div v-for="(item, index) in detail.annexList || []" :key="index">
            <a :href="item.url">附件{{ index + 1 }}</a>
          </div>
        </a-descriptions-item>
      </a-descriptions>
      <div class="ant-descriptions-title mt-24">商品列表</div>
      <data-list
        ref="dataList"
        class="flex-grow"
        :url="url"
        :query="query"
        entity-name="商品"
        hide-query
        hide-show-detail
        hide-edit
        hide-delete
        show-row-num
        :rules="rules"
        :columns="columns"
        :action="action"
      >
        <template v-slot:edit="{ form }">
          <a-form-model-item label="商品名称" prop="subscribe_goods">
            <a-auto-complete
              placeholder="输入商品名称"
              :data-source="filterCommodityOptions"
              v-model="form.subscribe_goods"
              allowClear
              @search="commoditySearch"
            >
            </a-auto-complete>
          </a-form-model-item>
          <a-form-model-item label="申购数量" prop="num">
            <a-input-number
              v-model="form.num"
              placeholder="请输入数字"
              :min="1"
              :step="1"
              allowClear
              style="width: 100%;"
            ></a-input-number>
          </a-form-model-item>
        </template>
        <template v-slot:action="{ item, index }">
          <div class="row-btn">
            <a class="txt-btn" @click.stop="editRow(item, index)" v-action:update :disabled="getDisabled(item)">编辑</a>
            <a class="txt-btn danger" @click.stop="delRow(item, index)" v-action:delete :disabled="getDisabled(item)">
              删除
            </a>
          </div>
        </template>
      </data-list>
    </div>
    <a-space class="footer">
      <a-button @click="back">返回</a-button>
    </a-space>
  </div>
</template>

<script>
import { isEmpty, timestampConvertString } from "../../common/js/tool";

export default {
  name: "PurchaseDetail",
  data() {
    return {
      url: "/admin/asset-subscribe",
      list: [],
      columns: [
        { title: "商品名称", dataIndex: "subscribe_goods" },
        { title: "数量", dataIndex: "num" },
      ],
      rules: {
        subscribe_goods: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        num: [{ required: true, message: "请输入申购数量", trigger: "blur" }],
      },
      filterCommodityOptions: [],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    query() {
      return `&filter[subscribe_num]=${this.id}&sort=-id&expand=tangkou,goods&filter[fixed_id][is]=null`;
    },
    detail() {
      let res = this.list[0];
      if(res && res.annex) {
        try {
          res.annexList = JSON.parse(res.annex);
        } catch (e) {
          res.annexList = res.annex.split(",").map(r => new Object({url: r}));
        }
      }
      return res;
    },
    action() {
      return this.$route.meta.action;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    timestampConvertString,
    getDisabled(item) {
      // 已入库或出库
      return item.status > 1;
    },
    getList() {
      this.$axios({
        url: this.url,
        method: "GET",
        params: {
          "filter[subscribe_num]": this.id,
          sort: "-subscribe_date,-create_time",
          expand: "tangkou,goods",
          page: 1,
          pageSize: 1,
        },
      }).then((res) => {
        this.list = res.data;
      });
    },
    commoditySearch(searchText) {
      if (isEmpty(searchText)) {
        this.filterCommodityOptions = [];
      } else {
        this.filterCommodityOptions = this.commodityOptions
          .filter((commodity) => commodity.name.indexOf(searchText) > -1)
          .map((commodity) => commodity.name);
      }
    },
    back() {
      this.$router.push({ name: "PurchaseList", query: { tab: 2 } });
    },
    editRow(item, index) {
      this.$refs.dataList.editRow(item, index);
    },
    delRow(item, index) {
      this.$refs.dataList.delRow(item, index);
    },
  },
};
</script>

<style lang="less" scoped>
.mt-24 {
  margin-top: 24px;
}
</style>
